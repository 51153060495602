// import React, { useEffect, useRef } from "react";
import { useRef, useState } from "react";

import axios from "axios";
import Webcam from "react-webcam";

const App = () => {
  // const videoRef = useRef(null);
  // const photoRef = useRef();

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .getUserMedia({
  //       video: { width: 1080, height: 1920 },
  //     })
  //     .then((stream) => {
  //       let video = videoRef.current;
  //       video.srcObject = stream;
  //       video.play();
  //     });
  // }, [videoRef]);


  // const takePhoto = () => {
  //   const width = 1080;
  //   const height = 1920;
  //   // const height = width / (16 / 9);
  //   let video = videoRef.current;
  //   let photo = photoRef.current;
  //   // photo.width = width;
  //   // photo.height = height;
  //   let context = photo.getContext("2d");
  //   context.drawImage(video, 0, 0, width, height);

  //   const formData = new FormData();
  //   formData.append("file", photo.toDataURL());
  //   formData.append("mobile_number", '1111111111');
  //   // make a POST request to the File Upload API with the FormData object and Rapid API headers
  //   axios
  //     .post("https://photobooth.teamr.live/Api/videoUploader", formData, {
  //       headers: {
  //        'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then((response) => {
	// 	// handle the response
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       // handle errors
  //       console.log(error);
  //     });
    
  // };


  const constraints = {
    width: 1080,
    height: 1920,
    facingMode: "user",
    // aspectRatio: 9 / 16,
  };
  const camRef = useRef();
  const [loading, setLoading] = useState(false);
  // const [id, setId] = useState("");
  // const [prevURL, setPrevURL] = useState("");
  const captureAndUpload = async () => {
    const data = camRef.current.getScreenshot({width: 1080, height: 1920});
    
    const formData = new FormData();
    formData.append("file", data);
    formData.append("mobile_number", '1111111111');
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post("https://photobooth.teamr.live/Api/videoUploader", formData, {
        headers: {
         'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
		// handle the response
        alert("Success");
        // console.log(response);
      })
      .catch((error) => {
        // handle errors
        alert("Error");
        // console.log(error);
      });
  };

  return (
    <div className="app">

      
      <div className="cameraBox">
          {/* <video className="camera" ref={videoRef}></video> */}
          <Webcam
              className="camera"
              ref={camRef}
              videoConstraints={constraints}
              screenshotFormat="image/jpeg"
            />
      </div>

      <div className="buttonBox">
        <button
              disabled={loading}
              onClick={captureAndUpload}
              className="button"
            >Take Photo</button>
            
        {/* <button
          className="button"
          onClick={() => takePhoto()}
        >
          Take Photo
        </button> */}
      </div>
      
      
      {/* photoRef && <canvas className="image" ref={photoRef}></canvas> */}
    </div>
  );
};

export default App;